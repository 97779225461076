import React from "react";

export default function Texto1() {
  return (
    <>
      <div className="text-justify">
        El ligamento cruzado anterior, o LCA , es el ligamento más importante de
        la rodilla para evitar que la rodilla se deslice hacia adelante o gire
        anterolateralmente. Los pacientes que sufren un desgarro del LCA a
        menudo tienen problemas con las actividades de torsión y giro, como
        jugar fútbol, ​​fútbol o esquiar, y a menudo requerirán una
        reconstrucción del LCA para brindar estabilidad a la rodilla.
        <br></br>
        <br></br>
        Además de su función de estabilidad en la rodilla, el ligamento cruzado
        anterior también proporciona protección para los meniscos de la rodilla.
        Cuando la rodilla continúa teniendo episodios de inestabilidad, no es
        raro que el menisco medial o lateral se desgarre. Sin embargo, cuando
        los meniscos se desgarran, existe un riesgo mucho mayor de desarrollar
        osteoartritis . Debido a esto, en generalmente se recomienda la cirugía
        de LCA y que se realice una reconstrucción de LCA en pacientes jóvenes o
        activos de otra manera y en casi todos los pacientes que informan
        inestabilidad con actividades de torsión o giro.
        <br></br>
        <br></br>
        Una lesión del LCA se clasifica según la magnitud de la lesión del
        ligamento:
        <br></br>
        <br></br>
        <ul className="list-disc">
          <li>*Grado I: desgarro parcial del LCA</li>
          <li>*Grado II: desgarro del LCA casi completo</li>
          <li>
            *Grado III: desgarro completo del LCA: el ligamento no es funcional
          </li>
        </ul>
        <br></br>
        Los atletas que tienen alguna dificultad para girar, girar o pivotar
        después de un desgarro del LCA deben considerar la reconstrucción de su
        LCA. Esto se debe a que los mecanismos de torsión y giro repetidos
        pueden dañar tanto el cartílago de la articulación como los meniscos. El
        menisco medial es el que tiene mayor riesgo de lesionarse con un
        desgarro del LCA porque el menisco medial asume gran parte de la función
        del LCA cuando se desgarra para evitar que la rodilla se deslice hacia
        adelante. Además, los pacientes que puedan tener un desgarro de menisco
        reparable en el momento de su desgarro del LCA deben considerar la
        cirugía para evitar que el desgarro se vuelva irreparable. En general,
        la mayoría de las personas que se desgarran los meniscos desarrollarán
        artritis y tendrán síntomas entre 8 y 10 años después de la rotura del
        LCA.
      </div>
    </>
  );
}
