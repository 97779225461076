import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor } from "@fortawesome/free-solid-svg-icons";

export default function Informativo() {
  const features = [
    {
      name: "¿Qué entrenamiento curricular tiene?",
      description: "",
      icon: faUserDoctor,
    },
    {
      name: "¿Comentarios y opiniones?",
      description: "",
      icon: faUserDoctor,
    },
    {
      name: "¿Te explicó ampliamente tu padecimiento y ofreció todas las opciones terapéuticas?",
      description: "",
      icon: faUserDoctor,
    },
    {
      name: "¿Qué experiencia y número de cirugías ha realizado en un año?",
      description: "",
      icon: faUserDoctor,
    },
    {
      name: "¿Qué trayectoria, credenciales y diplomas tiene?",
      description: "",
      icon: faUserDoctor,
    },
  ];
  return (
    <>
      <div className="informativo bg-fixed">
        <div className="max-w-full py-16  mx-auto px-4 sm:px-6 lg:px-8 interno">
          <div className="lg:text-center">
            <p className="mt-2 text-2xl leading-8 font-bold tracking-wide text-lime-600 sm:text-4xl">
              Te invitamos a revisar y comparar nuestra trayectoria profesional.
            </p>
            <p className="mt-4 max-w-2xl text-2xl text-white lg:mx-auto">
              ¿Qué tienes que saber antes de elegir a un cirujano?
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-8 w-8 rounded-md bg-sky-500 text-white">
                      <FontAwesomeIcon
                        icon={feature.icon}
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-white">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-100">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
