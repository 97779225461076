import React from "react";

export default function Texto6() {
  return (
    <>
      <div>
        Una plica de rodilla es un pliegue de tejido en forma de repisa dentro
        de la rodilla que normalmente desaparece en el 5º a 6º mes de gestación.
        Muy frecuentemente no desaparece en su totalidad y hasta el 90% de la
        población tiene un remanente de este tejido. La mayor parte de las veces
        sin darse cuenta.
        <br></br>
        <br></br>
        Se puede presentar en distintas áreas de la rodilla, siendo por encima
        de la rótula y la que entre la rótula y el fémur por la parte medial de
        la rodilla las localizaciones más frecuentes.
        <br></br>
        <br></br>
        Generalmente estas estructuras no dan ningún problema sin embargo entre
        el 10 y 20% de los pacientes con plicas presentan alguna sintomatología
        en algún momento de su vida. Sin embargo es importante mencionar que el
        que encontremos una plica por clínica o en una imagen de ultrasonido o
        por resonancia magnética no significa que tenga que operarse si no está
        dando sintomatología y debe intentarse siempre el tratamiento no
        quirúrgico.
        <br></br>
        <br></br>
        Los síntomas que produce la plica se deben al roce y/o pellizcamiento de
        esta entre la estructuras de la rodilla. Siendo la que más problemas da
        la ventromedial ya que se atrapa entre la rótula y el fémur produciendo
        dolor y llegando a causar erosiones en el cartílago articular.
        <br></br>
        <br></br>
        Desgraciadamente es muy fácil confundir la sintomatología entre una
        plica ventromedial y una lesión de menisco o de cartílago articular
        llegando a llamarle “La Gran Simuladora” y no es raro que si no se
        realiza un diagnóstico adecuado se realicen procedimientos enfocados en
        tratamiento de una lesión de menisco cuando en realidad el problema
        viene de una plica inflamada.
        <br></br>
        <br></br>
        El diagnóstico se realiza mediante exploración física, estudios de
        imagen como ultrasonido y/o resonancia magnética.
        <br></br>
        <br></br>
        Una plica puede “vivir” por años en la rodilla sin dar ningún problema,
        hasta que en algún movimiento se atrapa y se inflama y una vez inflamada
        se pellizca con mayor facilidad.
        <br></br>
        <br></br>
        Siempre se intenta tratar con reposo, antinflamatorios y terapia física
        de forma inicial al menos por un par de semanas. Si no hay respuesta
        adecuada se puede realizar una infiltración con algún medicamento
        antinflamatorio (usualmente algún derivado de la cortisona) y anestesia
        que a la vez nos sirve como maniobra de diagnóstico ya que si al
        inyectar el anestésico sobre la región de la plica sospechosa el dolor
        desaparece podemos determinar casi al 100% que el problema viene de este
        lugar. Y, al aplicar el antiinflamatorio se produce una desinflamación
        paulatina de la plica con lo cual muy frecuentemente deja de molestar.
        <br></br>
        <br></br>
        Cuando estas medidas funcionan existe la posibilidad de que el problema
        no se vuelva a repetir, sin embargo, cuando los síntomas no desaparecen
        y/o reaparecen frecuentemente es necesario retirarla mediante
        artroscopia, donde a través de 2 pequeñas incisiones de 4mm, se
        introduce una cámara e instrumental para cortar y retirar este tejido y
        tratar a la vez el daño que pudiera haber causado en el cartílago
        circundante.
        <br></br>
        <br></br>
        Esta es una cirugía rápida, habitualmente muy sencilla que se maneja
        casi en todos los casos de forma ambulatoria pudiendo regresar el
        paciente a su casas a las 2 o 3 horas de la cirugía y permitiéndole el
        apoyo y la movilidad de forma inmediata.
        <br></br>
        <br></br>
        Existen también plicas en otras partes del cuerpo como el codo y la
        cadera siendo mucho menos frecuentes y requiriendo muy rara vez algún
        tratamiento.
      </div>
    </>
  );
}
