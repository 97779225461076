import React from "react";

export default function Texto5() {
  return (
    <>
      <div>
        El Cartílago Articular es el tejido que recubre las terminaciones de
        cada hueso en todas las articulaciones del cuerpo, es una superficie muy
        especializada que permite un deslizamiento adecuado y amortiguación de
        las cargas de las articulaciones.
        <br></br>
        <br></br>
        El desgaste progresivo del cartílago asemeja el daño a la pintura de un
        automóvil o una pared en donde una vez que comienza el daño este va
        progresando paulatinamente hasta perderse en su totalidad. Este desgaste
        es en cierto punto esperado en toda la población. Sin embargo este
        desgaste normal es lento y en teoría debería de mantener saludables las
        articulaciones por al menos 70 años. En ocasiones el daño se ve
        acelerado por golpes, deformidades en la articulación, lesiones
        deportivas como lesiones de menisco, de ligamentos, etc.
        <br></br>
        <br></br>
        Una vez que el cartílago sufre una lesión, este no cicatrizara de forma
        adecuada, en especial si la lesión es profunda. Esto se debe a que el
        cartílago articular tiene la desventaja de no tener irrigación
        sanguínea. Es decir no tiene arterias ni venas en su interior, ya que se
        nutre del lubricante de la rodilla (liquido sinovial). Esto presenta una
        desventaja porque al no tener circulación sanguínea, cuando es lastimado
        no tiene la capacidad de cicatrizar, ya que no tiene acceso a factores
        de reparación, células madre, ni los nutrientes necesarios para
        repararse.
        <br></br>
        <br></br>
        Es muy común escuchar recomendaciones de amigos, compañeros, familiares,
        publicidad en televisión e incluso de médicos que con buena intensión
        recomiendan o prescriben todo tipo de tratamientos enfocados a la
        reparación, fortalecimiento y preservación del cartílago articular. Sin
        embargo solo un par de ellos están adecuadamente probados y recomendados
        por autoridades científicas en el tema. Existen por ejemplo, diversos
        medicamentos que prometen restablecer o fortalecer el cartílago
        articular, sin embargo actualmente no existe un medicamento que en
        realidad haya demostrado que repara o fortalece el cartílago de forma
        eficaz.
        <br></br>
        <br></br>
        Existen varias opciones reales de reparación de las lesiones del
        cartílago articular todas ellas de efectividad probada que forman parte
        de las recomendaciones actuales de las organizaciones de renombre como
        la International Cartilage Research Society (ICRS), la Academia
        Americana de Ortopedia (AAOS) y la Academia Americana de Artroscopia
        (AANA).
        <br></br>
        <br></br>
        La más frecuente y económica. Que actualmente sigue siendo la primera
        opción a nivel mundial en el tratamiento de lesiones de cartílago es el
        de las Microfracturas. En este procedimiento se retira el cartílago
        dañado, y se realizan perforaciones múltiples en el hueso subyacente
        penetrando hasta el hueso profundo donde se almacenan las células madre
        del cuerpo, con estas perforaciones se le da al cartílago la capacidad
        de cicatrizar al brindarle acceso a sangre, células madre y factores de
        crecimiento.
        <br></br>
        <br></br>
        Otra opción es la Mosaicoplastía que consiste en la transferencia de un
        bloque de hueso y cartílago tomado de un área poco importante de la
        rodilla y colocándola en áreas importantes de esta misma rodilla u otra
        articulación. Esta opción es muy efectiva sin embargo se limita a
        lesiones no muy grandes y que se encuentren solo en algunas áreas de la
        articulación.
        <br></br>
        <br></br>
        La opción más nueva es la del Implante de Condrocitos Cultivados en la
        cual se reproduce en laboratorio, cartílago tomado del paciente
        cultivándolo por semanas y permitiendo colocar un “parche” de cartílago
        sobre el defecto. Esta opción es muy prometedora sin embargo sus altos
        costos no han permitido que sea la técnica más utilizada.
        <br></br>
        <br></br>
        Existen algunas otras técnicas en desarrollo e investigación y no están
        aún disponibles en nuestro país pero prometen darnos mayores opciones en
        el futuro.
        <br></br>
        <br></br>
        Retomando el ejemplo de una pared una lesión de cartílago articular
        equivale al daño causado por un mueble o una puerta que golpea la pared
        provocando un defecto parcial el cual puede ser reparado dejando mínimo
        rastro del daño. Esto equivaldría a cualquiera de las tres técnicas
        descritas. Sin embargo, cuando la lesión es demasiado grande ninguna de
        estas técnicas lograra una reparación suficiente y al igual que una
        pared muy dañada o la pintura de un auto es preferible sustituirla de
        forma completa que en el caso de las articulaciones consiste en el
        reemplazo protésico.
        <br></br>
        <br></br>
        Es muy importante que las lesiones se atienden en forma temprana
        permitiéndonos retrasar de forma importante la progresión del daño y
        obtener una mejoría muy satisfactoria cuando se elige la técnica
        apropiada y la realiza alguien adecuadamente entrenado.
      </div>
    </>
  );
}
