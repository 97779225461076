import React from "react";

export default function Texto4() {
  return (
    <>
      <div>
        A pesar de la preparación multidisciplinar que realizan todos los
        deportistas que participan en los Juegos Olímpicos, muchos corren con la
        mala suerte de sufrir accidentes. Incluso, un reciente artículo
        divulgado en Journal of Clinical Orthopaedics and Trauma sugiere que
        entre un 10 y un 14 % de los atletas olímpicos sufren algún tipo de
        lesión.
        <br></br>
        <br></br>
        La razón de esto obedece en gran medida a los ejercicios físicos
        intensos y la ejecución de alto rendimiento que implican estos juegos.
        De cualquier modo, en algunos casos son tan graves, que no solo
        representan el fin de la participación en la competencia, sino el cierre
        de la carrera deportiva.
        <br></br>
        <br></br>
        Los episodios de lesiones y accidentes no se han quedado por fuera de
        las ediciones de los Juegos Olímpicos. A lo largo de la historia de este
        evento se han presentado múltiples hechos trágicos que han frustrado los
        sueños de muchos deportistas, entrenadores y fanáticos. ¿Cuáles son las
        más recordadas?
        <br></br>
        <br></br>
        El listado es bastante amplio si consideramos todas las versiones de las
        olimpiadas. Aun así, hay algunas que se destacan por las circunstancias
        en que se presentaron, o bien por sus desenlaces fatales.
        <br></br>
        <br></br>
        <h1 className="font-bold">1. Estocolmo 1912</h1>
        <br></br>
        Durante los Juegos Olímpicos de Estocolmo en 1912, el corredor de
        maratón olímpico portugués Francisco Lázaro sufrió las consecuencias del
        intenso calor de la época. Aunque en un principio se sugirió suspender
        la prueba, los organizadores se negaron. Por desgracia, esta decisión le
        costó la vida al corredor de 21 años, quien cayó muerto alrededor del
        kilómetro 30 de la carrera. De acuerdo con la autopsia, el deportista
        sufrió un grave desequilibrio electrolítico como consecuencia de un
        subóptimo proceso de transpiración. Se cree que este último fue
        interrumpido por el bloqueador solar que se aplicó para evitar las
        quemaduras.
        <br></br>
        <br></br>
        <h1 className="font-bold">2. Seúl 1988</h1>
        <br></br>
        En el año 1988, los Juegos Olímpicos tuvieron lugar en Seúl. Allí, el
        clavadista estadounidense Greg Louganis sufrió un accidente durante las
        rondas preliminares. Tras hacer un clavado invertido con dos giros y
        medio en el trampolín de tres metros, el agua se tiñó de sangre. El
        deportista sufrió una herida en su cabeza y, adicional a esto, tuvo una
        mala puntuación. No obstante, salió bien librado del golpe y clasificó a
        una siguiente ronda. Al final, se quedó con el oro de su categoría con
        un clavado similar al de su accidente.
        <br></br>
        <br></br>
        <h1 className="font-bold">3. Albertville 1992</h1>
        <br></br>
        Los Juegos Olímpicos de Invierno están asociados a un mayor riesgo de
        lesión en comparación con los del verano. Esto se atribuye al hecho de
        que en esta época se juegan deportes como el snowboard y el esquí. Por
        ello, no es extraño que una de las lesiones recordadas de estos juegos
        haya ocurrido precisamente en la época invernal. Fue durante los juegos
        de 1992 en Albertville, cuando el esquiador Nicholas Bochatay falleció
        tras hacer un calentamiento y perder el control de la velocidad. En
        concreto, el deportista se estrelló contra una máquina recogedora de
        nieve y el impacto le ocasionó la muerte.
        <br></br>
        <br></br>
        <h1 className="font-bold">4. Sidney 2000</h1>
        <br></br>
        En los Juegos Olímpicos de Sidney 2000 se presentó un accidente que le
        ocasionó dolor hasta a los espectadores. El atleta ucraniano Denys
        Yurchenko se dispuso a realizar un salto con pértiga -considerado uno de
        los más difíciles -, pero al caer sufrió un fuerte golpe en la ingle y
        los testículos. Para ser más exactos, al descender fue alcanzado por la
        vara (pértiga), y esta le provocó un corte en un músculo de la ingle.
        Por fortuna, se recuperó y logró una medalla de bronce en un ciclo
        olímpico posterior.
        <br></br>
        <br></br>
        <h1 className="font-bold">5. Beijing 2008</h1>
        <br></br>
        Se celebraban los Juegos Olímpicos Beijing 2008, cuando un 13 de agosto
        los espectadores presenciaron una de las lesiones más impresionantes. La
        víctima fue el levantador de pesas húngaro, Janos Baranyai, quien
        intentó levantar 148 kilos en su prueba. Sin embargo, las cosas salieron
        bastante mal; su brazo se venció hacia atrás y tuvo que ser remitido a
        urgencias al instante. Por obvias razones, esto representó el fin de su
        participación en la competencia mundial. Incluso, el deportista tuvo que
        estar más de un año en reposo antes de volver a levantar pesas.
        <br></br>
        <br></br>
        <h1 className="font-bold">6. Vancouver 2010</h1>
        <br></br>
        Otro hecho trágico ocurrió durante los Juegos Olímpicos de Invierno en
        Vancouver 2010. Durante su celebración, el georgiano Nodar
        Kumaritashvili falleció luego de estrellarse contra un poste de acero al
        perder el control de su trineo en uno de los entrenamientos. Aunque al
        momento del accidente recibió asistencia médica, fue declarado muerto al
        llegar al hospital.
        <br></br>
        <br></br>
        <h1 className="font-bold">7. Río 2016</h1>
        <br></br>
        En la pasada edición de las olimpiadas, Río 2016, la suerte no acompañó
        al gimnasta francés Samir Ait Said de 26 años. El deportista
        especializado en anillas y salto frustró su sueño olímpico tras sufrir
        un accidente al intentar hacer un salto de caballo durante la prueba
        clasificatoria. La caída le provocó una fractura que lo dejó por fuera
        de la competencia. Tras travesar un complejo proceso de rehabilitación,
        en 2019 regresó a las competencias y logró un cupo para los Juegos
        Olímpicos de Tokio 2020 que se celebrarán entre el 23 de julio y el 8 de
        agosto de 2021.
      </div>
    </>
  );
}
