import { useState } from "react";
import foto from "../assets/img/dr_fernando_hp.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import InformacionCurriculum from "./InformacionCurriculum";

export default function ExampleCurriculum() {
  const [collapse, setCollapse] = useState(true);
  const features = [
    {
      name: "Cirujano Ortopédico Cerificado",
      description: "",
    },
    {
      name: "Alta Especialidad en Cirugia Articular",
      description: "",
    },
    {
      name: "Alta Especialidad en Artroscopia y Lesiones Deportivas",
      description: "",
    },
    {
      name: "​Experto en: Artroscopia, Lesiones de Hombro, Rodilla, Codo, Tobillo, Protesis, Fracturas, etc.",
      description: "",
    },
  ];

  return (
    <>
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
          <div>
            <h2 className="text-3xl font-extrabold tracking-tight text-sky-900 sm:text-4xl">
              Currículum
            </h2>
            <p className="mt-4 text-gray-500">
              El Dr. Fernando Hernández Pérez cuenta con un amplio conocimiento:
            </p>

            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  className="border-t-2 border-blue-200 pt-4"
                >
                  <dt className="font-medium text-gray-900">{feature.name}</dt>
                  <dd className="mt-2 text-sm text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="content-center place-items-center grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8 items-center">
            <img
              src={foto}
              alt="dr fernando hernández pérez"
              className="bg-gray-100 rounded-lg"
            />
            <a
              href="https://mx.linkedin.com/in/fernando-hern%C3%A1ndez-p%C3%A9rez-78602136?trk=people-guest_people_search-card"
              className="w-full bg-sky-900 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="h-6 w-6 px-2" icon={faLinkedin} /> Ver
              en Linkedin
            </a>
            <button
              type="button"
              className="w-full bg-sky-900 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              data-bs-toggle="collapse"
              data-bs-target="#collapseCurriculum"
              aria-expanded="false"
              aria-controls="collapseCurriculum"
              onClick={() => setCollapse((current) => !current)}
            >
              {collapse ? (
                <>
                  <FontAwesomeIcon
                    className="h-6 w-6 px-2"
                    icon={faChevronUp}
                  />
                  <span>Ocultar más</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    className="h-6 w-6 px-2"
                    icon={faChevronDown}
                  />
                  <span>Mostrar más</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {collapse && (
        <div className="bg-gray-200 ">
          <InformacionCurriculum />
        </div>
      )}
    </>
  );
}
