import React from "react";

export default function Texto2() {
  return (
    <>
      <div className="text-justify">
        El término “hombro del lanzador” hace referencia a la enfermedad que
        padecen aquellos atletas que tienen actividades deportivas cuya técnica
        implica la elevación del brazo por encima de la cabeza, como en el
        tenis, pádel, natación, béisbol, voleibol, lanzadores, aunque también es
        común ver lesiones similares en el crossfit.
        <br></br>
        <br></br>
        Estas lesiones incluyen daños al reborde glenoideo, lesiones parciales
        de manguito rotador (PASTA) lesiones de SLAP y del bíceps.
        <br></br>
        <br></br>
        Existen diferentes tipos de lesión y grados entre ellos.
        <br></br>
        <br></br>
        Tipo I. Supone aproximadamente el 10% de este tipo de lesiones. Existe
        una lesión degenerativa del labrum superior, que está como deshilachado
        pero no está desinsertado ni la inserción del bíceps está dañada.
        <br></br>
        <br></br>
        Tipo II. Existe una desinserción del labrum y de la inserción del bíceps
        de la glena superior, del tubérculo supraglenoideo. Supone el 40% de las
        lesiones de este tipo.
        <br></br>
        <br></br>
        Tipo III. Existe una lesión en asa de cubo del labrum glenoideo en su
        porción superior en la cual no se ve afectado la inserción del tendón de
        la porción larga del bíceps. Se observa en el 30% de este síndrome.
        <br></br>
        <br></br>
        Tipo IV, existe una lesión en asa de cubo del labrum y afectación de la
        inserción del bíceps. Representa el 15 % de estas lesiones.
        <br></br>
        <br></br>
        El tratamiento depende del tipo de lesión. Las lesiones tipo I y III
        pueden tratarse con un simple desbridamiento artroscópico, seguido de un
        programa de fisioterapia y rehabilitación específicos.
        <br></br>
        <br></br>
        Las lesiones tipo II y IV requieren una estabilización quirúrgica, por
        artroscopia, del complejo bíceps-labrum, con buenos resultados
        funcionales.
      </div>
    </>
  );
}
