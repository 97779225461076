export default function Redes() {
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
          <h2 className="text-4xl text-center font-bold text-lime-600">
            Síguenos
          </h2>

          <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
            {/* ITEM */}
            <div className="group relative">
              <div className="relative w-full h-80 bg-white rounded-lg overflow-auto overscroll-contain sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                <a
                  id="zl-url"
                  className="zl-url"
                  href="https://www.doctoralia.com.mx/fernando-hernandez-perez/ortopedista-traumatologo/san-luis-potosi"
                  rel="nofollow"
                  data-zlw-doctor="fernando-hernandez-perez"
                  data-zlw-type="big"
                  data-zlw-opinion="true"
                  data-zlw-hide-branding="true"
                >
                  Fernando Hernández Pérez - Doctoralia.com.mx
                </a>
              </div>
            </div>
            <div className="group relative">
              <div className="bg-white relative w-full h-80 rounded-lg overflow-hidden sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                <div
                  className="fb-page w-full"
                  data-href="https://www.facebook.com/Alta.Especialidad.Ortopedia.y.Deporte"
                  data-tabs="timeline"
                  data-width=""
                  data-height=""
                  data-small-header="false"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="false"
                >
                  <blockquote
                    cite="https://www.facebook.com/Alta.Especialidad.Ortopedia.y.Deporte"
                    className="fb-xfbml-parse-ignore"
                  >
                    <a href="https://www.facebook.com/Alta.Especialidad.Ortopedia.y.Deporte">
                      Alta Especialidad en Ortopedia y Deporte
                    </a>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="group relative">
              <div className="relative w-full h-80 bg-white rounded-lg overflow-auto overscroll-contain sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                <a
                  className="twitter-timeline "
                  href="https://twitter.com/AEOrtopedia?ref_src=twsrc%5Etfw"
                >
                  Tweets by AEOrtopedia
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
