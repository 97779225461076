import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faStethoscope } from "@fortawesome/free-solid-svg-icons";

const sections = [
  {
    id: "CART",
    name: "CIRUGÍA ARTROSCÓPICA",
    items: [
      { name: "Lesiones de Manguito Rotador", href: "#" },
      { name: "Pinzamiento Subacromial", href: "#" },
      { name: "Luxaciones de Hombro", href: "#" },
      { name: "Lesiones de Cartilago Articular", href: "#" },
      { name: "Lesiones de Ligamento Cruzado Anterior y Posterior", href: "#" },
      { name: "Lesiones Multiligamentarias", href: "#" },
      { name: "Lesiones de Menisco", href: "#" },
      { name: "Síndrome Patelofemora", href: "#" },
      { name: "Inestabilidad de Tobillo", href: "#" },
      { name: "Fracturas Deportivas", href: "#" },
      { name: "Lesiones Musculares", href: "#" },
    ],
  },

  {
    id: "CRART",
    name: "CIRUGÍA DE REEMPLAZO ARTICULAR ",
    items: [
      { name: "Prótesis Total de Cadera", href: "#" },
      { name: "Prótesis Total de Rodilla", href: "#" },
      { name: "Prótesis Total de Hombro", href: "#" },
      { name: "Prótesis Total de Codo", href: "#" },
      { name: "Prótesis Total de Tobillo", href: "#" },
      { name: "Prótesis Complejas y Corrección de Fallas", href: "#" },
      { name: "Infecciones en Prótesis", href: "#" },
      { name: "Aflojamiento de Prótesis", href: "#" },
      { name: "Prótesis Dolorosa", href: "#" },
    ],
  },
  {
    id: "ER",
    name: "ESPECIALIDAD DE RODILLA",
    items: [],
  },

  {
    id: "TNEC",
    name: "TRATAMIENTO NO QUIRÚRGICO DE ENFERMEDADES DE COLUMNA ",
    items: [],
  },
  {
    id: "EH",
    name: "ESPECIALIDAD DE HOMBRO",
    items: [],
  },
];

export default function Servicese() {
  return (
    <div className="bg-white servicios bg-fixed">
      <div className="py-10 max-w-full mx-auto px-4 sm:px-6 lg:px-8 interno">
        <div className="lg:text-center">
          <div className="text-4xl font-extrabold tracking-widest text-gray-300">
            ¡SOMOS VERDADEROS
            <span className="text-lime-600"> EXPERTOS</span>!
          </div>

          <p className="mt-4 text-3xl leading-8 font-bold tracking-tight text-white sm:text-4xl">
            Servicios
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {sections.map((category) => (
              <div key={category.id} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-8 w-8 rounded-md bg-green-600  text-white">
                    <FontAwesomeIcon icon={faStethoscope} aria-hidden="true" />
                  </div>
                  <p className="text-green-400 ml-10 text-lg leading-6 font-medium">
                    {category.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-white">
                  {category.items.map((item) => (
                    <div key={item.name}>
                      <div className="absolute flex items-center justify-center h-5 w-5 rounded-md bg-green-600 text-white">
                        <FontAwesomeIcon icon={faCheck} aria-hidden="true" />
                      </div>
                      <p className="ml-10 text-lg leading-7 font-medium text-white">
                        {item.name}
                      </p>
                    </div>
                  ))}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
