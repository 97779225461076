import React from "react";

export default function Texto14() {
  return (
    <>
      <div>
        La articulación patelofemoral es la que se forma entre la patela
        (Rotula) y un surco sobre el fémur llamado tróclea que permite extender
        la rodilla cuando el musculo del cuádriceps se activa. Principalmente
        sucede al bajar o subir escaleras o pendientes.
        <br></br>
        <br></br>
        Este síndrome es muy común en México ya que la mayoría de las mujeres
        latinas tienen una alineación en Valgo (Las rodilla juntas y los
        tobillos separados) lo que causa que con el pase del tiempo la rótula
        reciba toda la carga en su porción lateral desgastando de forma anormal
        su recubrimiento de cartílago tanto en la rótula como en la parte
        externa de la tróclea femoral. Esto equivale a un neumático con mala
        alineación que se desgasta sobre uno de sus lados en un periodo de
        tiempo mucho menor que el de un neumático alineado correctamente.
        <br></br>
        <br></br>
        Los síntomas son el dolor de rodilla que predomina con la subida o
        bajada de escaleras o pendientes. Con menor dolor al permanecer de pie.
        <br></br>
        <br></br>
        Las opciones de tratamiento son múltiples dependiendo de la edad
        actividad la severidad de la alineación y el grado de lesión del
        cartílago articular.
        <br></br>
        <br></br>
        Siempre es imprescindible realizar un estudio de alineación de la
        articulación de la rodilla mediante radiografías y cuando es necesario
        un estudio de resonancia magnética que permita determinar el grado de
        afección del cartílago articular de la patela y la tróclea.
        <br></br>
        <br></br>
        Dependiendo de cada caso el tratamiento inicia con un programa intenso
        de rehabilitación encaminado a fortalecer el cartílago de la rótula y la
        parte medial del musculo cuádriceps para ayudar a que el
        “encarrilamiento” de la rótula en el surco de la tróclea sea lo más
        adecuado posible.
        <br></br>
        <br></br>
        Si el desgaste de la rótula inicia en etapas tempranas de la vida (Antes
        de los 40 años de edad) o la deformidad en valgo es muy severa, lo ideal
        es corregir la deformidad de la rodilla mediante un corte óseo que
        corrija el eje de carga de la rodilla antes de que el desgaste progrese.
        <br></br>
        <br></br>
        Si el daño es aparece entre los 40 y 60 años de edad lo ideal es
        realizar una alineación más simple alineando solo la parte de la rótula
        y la tróclea mediante una osteotomía de anteromedialización o una
        prótesis parcial de rotula y tróclea.
        <br></br>
        <br></br>
        Estos tratamientos siempre se acompañan con una evaluación por
        artroscopía integral de la rodilla y una reparación del cartílago
        articular dañado y demás anormalidades encontradas. De la misma forma
        estas son manejadas de forma ambulatoria y con una recuperación similar.
        La cirugía se realiza en un tiempo de 2 horas aproximadamente, con
        anestesia regional y después de entre 3 a 4 horas de recuperación el
        paciente egresa a su domicilio pudiendo deambular de forma inmediata con
        el uso de una rodillera especial y muletas.
        <br></br>
        <br></br>
        Está bien fundamentado mediante estudios clínicos que el realizar una
        alineación y reparación de cartílago articular a tiempo puede llegar a
        evitar o retrasar de forma importante la progresión a una prótesis total
        de rodilla sin embargo es importante realizar una evaluación a detalle
        de cada caso para brindar la opción más apropiada a cada paciente.
      </div>
    </>
  );
}
