import BackToTop from "../components/BackToTop";
import Banner from "./Banner";
import Especialidades from "./Especialidades";
import Servicios from "./Servicios";
import Informativo from "./Informativo";
import Footer from "./Footer";
import Citas from "./Citas";
import Informate from "./Informate";
import Curriculum from "./Curriculum";
import Opiniones from "./Opiniones";

import Redes from "./Redes";

export default function Inicio() {
  return (
    <>
      <Banner />
      <Especialidades />
      <Servicios />
      <Informate />
      <Informativo />
      <Curriculum />
      <Redes />
      <Opiniones />
      <Citas />
      <Footer />
      <BackToTop />
    </>
  );
}
