import React from "react";

export default function Texto10() {
  return (
    <>
      <div>
        El hombro es la articulación más móvil del cuerpo humano, con un
        conjunto de complejas estructuras (ligamentos, tendones y músculos) que
        interactúan para proporcionar el movimiento necesario para la vida
        diaria. Cada una de estas estructuras brinda una importante contribución
        para permitir el movimiento y la estabilidad del hombro. Ciertas
        actividades cotidianas o deportivas pueden lesionar estas estructuras
        cuando se sobrepasan los límites de carga o movimiento del hombro.
        <br></br>
        <br></br>
        El manguito rotador es un grupo de músculos y tendones que rodea la
        articulación del hombro y mantiene firme la cabeza del húmero en la
        cavidad poco profunda del hombro. Una lesión del manguito rotador puede
        provocar un dolor sordo en el hombro, que a menudo empeora con el uso
        del brazo alejado del cuerpo.
        <br></br>
        <br></br>
        Las lesiones del manguito rotador son frecuentes y aumentan con la edad.
        Esto puede ocurrir antes en personas que tienen trabajos que requieren
        realizar repetidamente movimientos por encima de la cabeza. Entre los
        ejemplos se incluyen pintores y carpinteros.
        <br></br>
        <br></br>
        <h1 className="font-bold">Causas</h1>
        <br></br>
        La enfermedad del manguito rotatorio puede ser el resultado de una
        lesión importante en el hombro, o una degeneración progresiva o un
        desgaste del tejido del tendón. La actividad repetitiva por encima de la
        cabeza o levantar objetos pesados durante un período de tiempo
        prolongado puede irritar o dañar el tendón.
        <br></br>
        <br></br>
        <h1 className="font-bold">Factores de riesgo </h1>
        <br></br>
        Los siguientes factores pueden aumentar el riesgo de tener una lesión
        del manguito rotador:
        <br></br>
        <ul className="list-disc">
          <li>
            · <span className="font-bold">Edad.</span> Con la edad, aumenta el
            riesgo de tener una lesión del manguito rotador. Los desgarres del
            manguito rotador son más frecuentes en personas mayores de 60 años.
          </li>
          <li>
            · <span className="font-bold">Trabajos de construcción.</span>{" "}
            Algunas ocupaciones, como la carpintería o la pintura de casas,
            requieren movimientos de brazos repetitivos, a menudo por encima de
            la cabeza, que pueden dañar el manguito rotador con el tiempo.
          </li>
          <li>
            · <span className="font-bold">Antecedentes familiares.</span> Puede
            haber un componente genético en las lesiones del manguito rotador,
            ya que es más común que aparezcan en determinadas familias.
          </li>
        </ul>
        <br></br>
        <br></br>
        <h1 className="font-bold">Prevención</h1>
        <br></br>
        Si corres el riesgo de tener lesiones del manguito rotador o si has
        tenido una lesión del manguito rotador en el pasado, los ejercicios
        diarios de fortalecimiento del hombro pueden ayudar a prevenir una
        lesión futura.
        <br></br>
        La mayoría de las personas ejercitan los músculos frontales del pecho,
        de los hombros y de la parte superior de los brazos, pero es igualmente
        importante fortalecer los músculos de la parte trasera de los hombros y
        alrededor del omóplato para mejorar el equilibrio muscular de los
        hombros. El médico o un fisioterapeuta pueden ayudarte a crear una
        rutina de ejercicios
      </div>
    </>
  );
}
