import React, { useState, useEffect } from "react";
import { ArrowSmUpIcon } from "@heroicons/react/outline";

const BackToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="top-to-btm">
        {showTopBtn && (
          <>
            <button
              type="button"
              onClick={goToTop}
              className="justify-center py-4 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <ArrowSmUpIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </>
        )}
      </div>
    </>
  );
};
export default BackToTop;
