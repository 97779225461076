import React from "react";

export default function Texto8() {
  return (
    <>
      <div>
        Un Menisco es un tejido muy especializado compuesto de cartílago algo
        similar al que forma la oreja. Compuesto por distintos tipos de células
        y fibras organizadas de una forma especial que le hacen resistir cargas
        de forma muy eficiente.
        <br></br>
        <br></br>
        Los meniscos actúan como un “empaque” en varias articulaciones del
        cuerpo como la rodilla, la mandíbula y la acromio clavicular a nivel del
        hombro.
        <br></br>
        <br></br>
        Estos “empaques” ayudan a repartir la carga que recibe la articulación,
        a distribuir el líquido lubricante formando una capa deslizante sobre la
        cual la articulación se mueve con poca fricción así como para brindar
        estabilidad en las articulaciones.
        <br></br>
        <br></br>
        Los meniscos más importantes son los que se encuentran en la rodilla y
        son a la vez los que más se pueden lesionar generalmente con rotaciones
        forzadas de la rodilla o movimientos que atrapan estas estructuras
        desgarrándolas.
        <br></br>
        <br></br>
        Los síntomas más frecuentes de una lesión de menisco son, dolor intenso,
        sensación de que algo se rompe dentro de la rodilla, inflamación,
        imposibilidad para el apoyo y sensación de que la articulación se
        “traba” o “atora”.
        <br></br>
        <br></br>
        Hasta hace un par de décadas se creía que los meniscos nos servían para
        nada y que no había consecuencias si estos eran removidos, inclusive se
        pensaba que de ser retirados estos se regeneraban y no fue sino hasta
        hace cerca de 20 años que se descubrió la función de estas estructuras y
        las consecuencias severas de su ausencia.
        <br></br>
        <br></br>
        El día de hoy sabemos que no todas las lesiones de menisco requieren
        tratamiento quirúrgico y que si lo llegan a requerir en el caso del
        menisco ¡menos es más!
        <br></br>
        <br></br>
        Existe una condición en la cual los meniscos en lugar de tener una forma
        de “C” tienen forma de Plato. Esta variante de forma se llama menisco
        discoide y por sí misma no es una enfermedad pero estos meniscos
        discoideos se desgarran mucho más fácil que los meniscos normales. Es
        frecuente que nos demos cuenta de que el menisco era discoide hasta que
        este se rompe y en este caso la cirugía consiste en repararlo y
        remodelarlo lo mejor que se pueda para dejarle la forma adecuada.
        <br></br>
        <br></br>
        Con la evolución de las técnicas artroscópicas al día de hoy es posible
        reparar mediante cirugía ambulatoria, a través de solo un par de heridas
        una gran cantidad de lesiones de menisco preservando al máximo la
        integridad de la articulación. Sin embargo existen aún algunas lesiones
        que por su localización o por su tamaño no son reparables. En estos
        casos siempre tratamos de retirar la menor cantidad de tejido de menisco
        solo lo suficiente para que los fragmentos del menisco lesionado no se
        “pellizquen” en la articulación.
        <br></br>
        <br></br>
        Actualmente incluso realizamos, con excelentes resultados, el trasplante
        de meniscos en aquellos pacientes que tuvieron una lesión demasiado
        extensa requiriendo la eliminación de un menisco en su totalidad. En
        estos pacientes se toman medidas apropiadas y se localiza en un banco de
        tejidos un menisco cadavérico de tamaño adecuado el cual se coloca en la
        rodilla afectada restaurando su función.
        <br></br>
        <br></br>
        Si tienes sospechas de tener alguna lesión menisco no dudes en acudir a
        la brevedad a ser valorado por un experto que determine si está o no
        lesionado tu menisco y si requieres o no algún tratamiento.
      </div>
    </>
  );
}
