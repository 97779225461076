import React from "react";
import img1 from "../assets/img/cirugia_deportiva.jpg";
import img2 from "../assets/img/cirugia_artroscopica.png";
import img3 from "../assets/img/fractura.png";
import img4 from "../assets/img/cirugia.jpg";
import img5 from "../assets/img/lesion_columna.png";
import img6 from "../assets/img/deportes.png";
import img7 from "../assets/img/dolor_hombro.webp";
import img8 from "../assets/img/dolor_lumbar.webp";
import img9 from "../assets/img/rehabilitacion_postquirurgica.webp";

export default function Especialidades() {
  const especialidades = [
    {
      id: 1,
      name: "Medicina Deportiva y Cirugía",
      description: "",
      imageSrc: `${img1}`,
      imageAlt: "image",
      href: "/",
    },
    {
      id: 2,
      name: "Artroscópica Cirugia de Reemplazo Articular",
      description: "",
      imageSrc: `${img2}`,
      imageAlt: "image",
      href: "#",
    },
    {
      id: 3,
      name: "Tratamiento Integral de Fracturas",
      description: "",
      imageSrc: `${img3}`,
      imageAlt: "image",
      href: "#",
    },
    {
      id: 4,
      name: "Tratamiento no quirúrgico de enfermedades de columna",
      description: "",
      imageSrc: `${img4}`,
      imageAlt: "image",
      href: "#",
    },

    {
      id: 5,
      name: "Dolor de Rodilla",
      description: "",
      imageSrc: `${img6}`,
      imageAlt: "image",
      href: "#",
    },
    {
      id: 6,
      name: "Dolor en el hombro",
      description: "",
      imageSrc: `${img7}`,
      imageAlt: "image",
      href: "#",
    },
    {
      id: 7,
      name: "Dolor lumbar o de espalda",
      description: "",
      imageSrc: `${img8}`,
      imageAlt: "image",
      href: "#",
    },
    {
      id: 8,
      name: "Rehabilitación postquirúrgica",
      description: "",
      imageSrc: `${img9}`,
      imageAlt: "image",
      href: "#",
    },
    {
      id: 100,
      name: "Lesiones de Columna Y Más...",
      description: "",
      imageSrc: `${img5}`,
      imageAlt: "image",
      href: "#",
    },
  ];

  return (
    <>
      <div className="bg-gray-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-1 lg:max-w-none">
            <div className="text-3xl text-center font-bold">
              TU SALUD EN MANOS DE UN
              <p className="text-5xl text-lime-600 tracking-widest"> EXPERTO</p>
            </div>
            <div className="mt-10 lg:text-center">
              <p className="mt-0 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                Especialidades
              </p>
            </div>

            <div className="bg-white">
              <div className="max-w-2xl py-10 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 bg-blue200">
                <h2 className="sr-only">Products</h2>

                <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                  {especialidades.map((item) => (
                    <div key={item.id} href={item.href} className="group">
                      <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                        <img
                          src={item.imageSrc}
                          alt={item.name}
                          className="w-full h-full object-center object-cover group-hover:opacity-75"
                        />
                      </div>
                      <h3 className="mt-4 text-md text-gray-700">
                        {item.name}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="polygon_2">
        <svg
          className="absolute bottom-0 overflow-hidden"
          preserveAspectRatio="none"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="polygon2 text-slate-200"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </>
  );
}
