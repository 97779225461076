import React from "react";
import logo from "../assets/img/Logo FOndo Transparente.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneSquare,
  faCircle,
  faStarOfLife,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faTiktok,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <>
      <footer className="text-center text-white lg:text-left bg-sky-900">
        <div className="flex items-center justify-center p-6 border-b border-gray-500 lg:justify-between"></div>
        <div className="py-10 mx-6 text-center md:text-left">
          <div className="grid gap-4 grid-1 md:grid-cols-2 lg:grid-cols-4">
            <div className="">
              <img src={logo} alt="logo" />
            </div>
            <div className="">
              <h6 className="flex items-center justify-center mb-4 font-semibold text-green-600 uppercase md:justify-start">
                Alta Especialidad en Ortopedia y Deporte
              </h6>
              <ol>
                <li>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="mr-2 text-white"
                  />
                  Medicina Deportiva y Cirugía Artroscópica
                </li>
                <li>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="mr-2 text-white"
                  />
                  Cirugía de Reemplazo Articular{" "}
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="mr-2 text-white"
                  />
                  Tratamiento Integral de Fracturas{" "}
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="mr-2 text-white"
                  />
                  Especialidad en rodillas
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="mr-2 text-white"
                  />
                  Especialidad en hombros
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="mr-2 text-white"
                  />
                  Tratamiento no quirúrgico de enfermedades de columna
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="mr-2 text-white"
                  />
                  Lesiones de Columna Y más...
                </li>
              </ol>
            </div>

            <div className="">
              <h6 className="flex justify-center mb-10 font-semibold text-green-600 uppercase md:justify-start">
                Contáctanos
              </h6>
              <span className="flex items-center justify-center mb-4 md:justify-start">
                <FontAwesomeIcon
                  icon={faPhoneSquare}
                  className="w-6 h-6 mr-2 text-white"
                />{" "}
                <a href="tel:444-817-5136">(444) 817-51-36</a>
                
              </span>
              <span className="flex items-center justify-center mb-4 md:justify-start">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="w-6 h-6 mr-2 text-white"
                />{" "}
                <a href="mailto:asistente@aeortopedia.com">asistente@aeortopedia.com</a>
              </span>

              <a
                href="https://www.doctoralia.com.mx/fernando-hernandez-perez/ortopedista-traumatologo/san-luis-potosi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="flex items-center justify-center mb-4 md:justify-start">
                  <FontAwesomeIcon
                    icon={faStarOfLife}
                    className="w-6 h-6 mr-2 text-white"
                  />
                  Doctoralia
                </span>
              </a>
            </div>
            <div className="">
              <h6 className="flex justify-center mb-10 font-semibold text-green-600 uppercase md:justify-start">
                Encuéntranos en
              </h6>
              <span className="flex items-center justify-center mb-4 md:justify-start">
                <a
                  href="https://wa.link/p6cttl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="w-8 h-8 mr-2 text-white"
                  />
                </a>
                <a
                  href="https://www.facebook.com/Alta.Especialidad.Ortopedia.y.Deporte"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="w-8 h-8 mr-2 text-white"
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@aeortopediaydeporte"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faTiktok}
                    className="w-8 h-8 mr-2 text-white"
                  />
                </a>
                <a
                  href="https://twitter.com/AEOrtopedia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="w-8 h-8 mr-2 text-white"
                  />
                </a>
                <a
                  href="https://www.instagram.com/aeortopedia/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="w-8 h-8 mr-2 text-white"
                  />
                </a>
                <a
                  href="https://mx.linkedin.com/in/fernando-hern%C3%A1ndez-p%C3%A9rez-78602136?trk=people-guest_people_search-card"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="w-8 h-8 mr-2 text-white"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="p-6 text-center text-white bg-green-600">
          <span>© 2017-2019 Alta Especialidad en Ortopedia y Deporte</span>
        </div>
      </footer>
    </>
  );
}
