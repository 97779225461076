import React from "react";

export default function Texto3() {
  return (
    <>
      <div>
        Sa Jae-Hyouk está entrenando de nuevo después de su horrible lesión en
        el codo en los Juegos Olímpicos de Londres . Sa Jae-Hyouk, quien se
        fracturó el codo en un terrible accidente en los últimos Juegos
        Olímpicos de Londres, ha recuperado su retiro. Incluso después de
        numerosas cirugías, regresó para ganar el oro en Beijing. Sa recibió un
        premio especial junto a la reina del levantamiento de pesas, Jang Miran.
        <br></br>
        <br></br>
        “Pensé que era un poco irónico, porque no es como si me estuviera
        retirando, seguiré levantando. Creo que este premio me fue otorgado para
        poder seguir haciendo un trabajo especial ”.
        <br></br>
        <br></br>
        Los últimos 6 meses de Sa han sido bastante oscuros. Un terrible
        accidente ocurrió en los Juegos Olímpicos de Londres. Su cuerpo, que ha
        sido sometido a 6 cirugías , y su mente cansada parecían haber llegado
        al límite. “Fue muy duro. Tenía pensamientos de retirarme del
        levantamiento de pesas, lo que más amo ".
        <br></br>
        <br></br>
        El contrato de Sa con su equipo actual, con el que ha estado durante los
        últimos 6 años, no ha terminado. Pero como siempre, Sa está preparando
        una remontada.
        <br></br>
        <br></br>
        “El futuro todavía me parece muy oscuro, como si estuviera dentro de un
        túnel largo y oscuro. Sin embargo, todavía tengo el deseo de volver y
        realizar una actuación honorable ".
        <br></br>
        <br></br>
        Incluso después de 5 cirugías, incluidas rodillas, muñecas, hombros y,
        más recientemente, su codo, Sa emergerá de la lucha solitaria y tediosa,
        a través del túnel oscuro aparentemente interminable, para enfrentar la
        sexta batalla consigo mismo.
      </div>
    </>
  );
}
