export default function InformacionCurriculum() {
  const features = [
    {
      id: 1,
      description:
        "Miembro de los Comités Internacional y de Membresia de la Arthroscopy Association of North America (AANA) 2020-2021",
      date: "2020 - 2021",
    },
    {
      id: 2,
      description:
        "Profesor Internacional Asociado de cirugía artroscópica de hombro y rodilla. Orthopedic Learning Center de Rosemont, Illinois. Arhroscopy Association of North America",
      date: "",
    },
    {
      id: 3,
      description:
        "3er Mexicano galardonado con el Advanced Arthroscopy Traveling Fellowship de la Arthroscopy Association of North America (AANA) en 2019",
      date: "2019",
    },
    {
      id: 4,
      description:
        "Miembro titular y profesor de la Asociación Mexicana de Cirugía Reconstructiva Articular y Artroscopia (AMECRA), del Colegio Mexicano de Ortopedia, (CMO) y de la Federación Mexicana de Colegios de Ortopedia y Traumatología ((FEMECOT)",
      date: "",
    },
    {
      id: 5,
      description:
        "Jefe del Servicio Médico Club de Basquetbol Profesional Santos de San Luis.",
      date: "",
    },
    {
      id: 6,
      description:
        "Jefe del Servicio Médico Club de Futbol Profesional Santos Soledad 2015- 2017",
      date: "2015 - 2017",
    },
    {
      id: 7,
      description:
        "Sports Medicine and Arthroplasty Visiting Fellowship New England Baptist Hospital, Boston, Massachussets, E.U.A. 2012",
      date: "2012",
    },
    {
      id: 8,
      description:
        "Alta Especialidad en Artroscopía y Lesiones Deportivas UNAM, Instituto Nacional de Rehabilitación, México, D.F. 2012",
      date: "2012",
    },
    {
      id: 9,
      description:
        "Alta Especialidad en Cirugía Articular, UNAM, Instituto Nacional de Rehabilitación, México, D.F. 2011",
      date: "2011",
    },
    {
      id: 10,
      description:
        "Rotación Clínica, Servicios de Columna, Miembro Inferior y Superior. en el Centre Hospitalier de Dax-Côte d´Argent, Dax, Francia. y la Clínica Quirón y Policlínica, San Sebastián, España. en 2008",
      date: "2008",
    },
    {
      id: 11,
      description:
        "Profesor Lesiones Deportivas, Facultad de Medicina Universidad Autónoma de San Luis Potosí , Materia de Ortopedia, Licenciatura en Medicina desde 2012 a la Fecha",
      date: "2012 - 2022",
    },
    {
      id: 12,
      description:
        "Especialidad de Ortopedia y Traumatología 2010 Universidad Autónoma de San Luis Potosi, Hospital Central “Ignacio Morones Prieto” S.L.P. México",
      date: "2010",
    },
    {
      id: 13,
      description:
        "Jefe de Residentes de la especialidad de Ortopedia y Traumatología 2009-2010 Universidad Autónoma de San Luis Potosí, S.L.P. México., Hospital Central “Ignacio Morones Prieto” S.L.P. México",
      date: "2009 - 2010",
    },
    {
      id: 14,
      description: "Certificación por el Consejo Mexicano de Ortopedia en 2010",
      date: "2010",
    },
    {
      id: 15,
      description:
        "Graduado de de la Licenciatura en Medicina General por la Facultad de Medicina Universidad Autónoma de San Luis Potosí en 2006",
      date: "2006",
    },
    {
      id: 16,
      description:
        "Estancia de Practica de Intercambio Internacional, Pritzker Medical School, Universidad de Chicago, Chicago Illinois E.U.A. en 2000",
      date: "2000",
    },
    {
      id: 17,
      description:
        "Testimonio de Desempeño Sobresaliente en el Examen General para el Egreso de la Licenciatura en Medicina General (EGEL-MG).",
      date: "",
    },
  ];
  return (
    <div className="py-1 bg-slate-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Experto en:
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div className="relative" key={feature.id}>
                <ol className="border-l-2 border-sky-900">
                  <li>
                    <div className="md:flex flex-start">
                      <div className="bg-sky-900 text-white w-6 h-6 flex items-center justify-center rounded-full -ml-3">
                        {/* icon */}
                      </div>
                      <div className="block p-6 rounded-lg shadow-lg bg-gray-100 max-w-md ml-6 mb-10">
                        <div className="flex justify-between mb-4">
                          <a
                            href="#!"
                            className="font-medium text-sky-600 hover:text-sky-700 focus:text-sky-800 duration-300 transition ease-in-out text-sm"
                          >
                            {feature.date}
                          </a>
                        </div>
                        <p className="text-gray-700 mb-6">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
