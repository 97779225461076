import React, { useState } from "react";
import ModalContent from "./ModalContent";
function Modal({ data }) {
  const [open, setIsOpen] = useState(false);
  return (
    <>
      <img
        onClick={() => setIsOpen(true)}
        className="shrink-0 w-64 h-full rounded-lg shadow-xl bg-white group-hover:opacity-75 "
        src={data.imageSrc}
        alt={data.imageAlt}
      />

      <div className="bg-green-100 border-blue-600">
        {/* <button onClick={() => setIsOpen(true)}>open</button> */}
        {open && (
          <ModalContent isOpen={setIsOpen}>
            <div className="overscroll-contain overflow-auto w-full h-96 justify-center">
              <h1 className="text-xl text-sky-900 font-bold text-center">
                {data.name}
              </h1>
              <div className="mt-4 text-justify">{data.text}</div>
            </div>
          </ModalContent>
        )}
      </div>
    </>
  );
}

export default Modal;
