import { useEffect, useState } from "react";
import logo from "../assets/img/logo_circular.png";
import Menu from "./Menu";

export default function Navbar() {
  // const [click, setClick] = useState(false);
  // const handleClick = () => setClick(!click);
  const [color, setColor] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function changeColor() {
      if (window.scrollY >= 80) {
        setColor(true);
      } else {
        setColor(false);
      }
    });
  }, []);

  return (
    <>
      <div className={color ? "header header-bg" : "header"}>
        <nav className="navbar">
          <a href="/" className="logo">
            <img src={logo} alt="logo" />
          </a>

          {/* <div className="hamburger" onClick={handleClick}>
            {click ? (
              <FaTimes size={30} style={{ color: "#ffffff" }} />
            ) : (
              <FaBars size={30} style={{ color: "#ffffff" }} />
            )}
          </div> */}
          <Menu />
          {/* <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a href="/">Home</a>
            </li>
          </ul> */}
        </nav>
      </div>
    </>
  );
}
