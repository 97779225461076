import React from "react";

export default function Texto9() {
  return (
    <>
      <div>
        El síndrome de la banda iliotibial se presenta cuando el tejido
        conectivo (ligamento) que se extiende desde el hueso de la pelvis hasta
        la tibia se tensa tanto que roza con el fémur. Suele manifestarse en los
        corredores de distancia. El síntoma principal es el dolor entre la
        cadera y las rodillas, que empeora con la actividad física. Tratamientos
        como la fisioterapia y, a veces, las inyecciones de corticosteroides
        pueden ser de ayuda. En algunos casos, puede ser necesario hacer una
        cirugía.
        <br></br>
        <br></br>
        El síndrome de la banda iliotibial es una lesión por fricción de tipo
        inflamatorio, no traumático por el uso excesivo constante de la rodilla,
        es generada por un contacto continuo entre la banda iliotibial y el
        epicóndilo lateral en la región externa e inferior de la rodilla, en la
        mayor parte de los casos se desarrolla en forma gradual como resultado
        de entrenamientos intensos o realizados de manera excesiva, muchas veces
        inadecuada. La frecuencia de esta lesión ha ido aumentando debido al
        incremento de lesiones cardiovasculares, obesidad y sobrepeso en los
        pacientes para quienes es importante y necesario hacer ejercicio como
        parte su tratamiento para un buen control del peso, mejoría del sistema
        cardiovascular y mantenerse en buenas condiciones físicas.
        <br></br>
        <br></br>
        Un elemento importante en la generación del síndrome es la sobrecarga y
        el uso excesivo de la rodilla, por tal motivo lo que sucede en
        corredores de distancias cortas o largas y en ciclistas es que generan
        un estrés repetitivo y prolongado en ésta. También es importante la
        superficie sobre la cual se ejercita la persona, no es raro ver que
        suban o bajen escaleras en exceso o que corran en superficies inclinadas
        con muchas subidas y bajadas.11 Los zapatos muy usados son inadecuados y
        causales de dolor, cuanto más gastado está el zapato se transfieren más
        fuerzas del suelo a la rodilla.
        <br></br>
        <br></br>
        <h1 className="font-bold">Tratamiento.</h1>
        <br></br>
        El objetivo final es devolver al deportista una rutina sin dolor. Los
        principios básicos del tratamiento para el SBIT incluyen controlar la
        inflamación, modificar la actividad y corregir los factores que puedan
        mantener o exacerbar el dolor. Habitualmente responden bien a un
        tratamiento conservador con una incidencia de buenos resultados que
        alcanza 94%. Una clasificación de la lesión ayuda a determinar el plan
        de tratamiento.
      </div>
    </>
  );
}
