import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faFacebook,
  faTwitter,
  faInstagram,
  faTiktok,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Menu = () => {
  return (
    <>
      <ul className="nav-menu">
        <li className="nav-item">
          <a
            href="https://wa.link/p6cttl"
            className="text-3xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </li>

        <li className="nav-item">
          <a
            href="https://www.facebook.com/Alta.Especialidad.Ortopedia.y.Deporte"
            className="text-3xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </li>
        <li className="nav-item">
          <a
            href="https://www.tiktok.com/@aeortopediaydeporte"
            className="text-3xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTiktok} />
          </a>
        </li>
        <li className="nav-item">
          <a
            href="https://twitter.com/AEOrtopedia"
            className="text-3xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li className="nav-item">
          <a
            href="https://www.instagram.com/aeortopedia/"
            className="text-3xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </li>

        <li className="nav-item">
          <a
            href="https://mx.linkedin.com/in/fernando-hern%C3%A1ndez-p%C3%A9rez-78602136?trk=people-guest_people_search-card"
            className="text-3xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li className="nav-item">
          <span className="text-3xl text-white">
            <FontAwesomeIcon icon={faPhoneSquare} />
            {" "}
            <a href="tel:444-817-5136">444-817-5136</a>
          </span>
        </li>
        {/* <li className="nav-item">
          <span className="text-3xl text-white">
            <FontAwesomeIcon icon={faPhoneSquare} />
          </span>
        </li> */}
      </ul>
    </>
  );
};

export default Menu;
