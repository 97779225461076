import React from "react";

export default function Citas() {
  return (
    <>
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="mt-2 text-center text-3xl leading-8 font-bold tracking-tight text-gray-700 sm:text-4xl">
              Visítanos o agenda tu cita aquí mismo
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="relative">
                {/* Google Maps */}
                {/* <iframe
                  className="w-full h-full"
                  title="aeortopedia"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3695.2979463522533!2d-101.02386558523963!3d22.152727885400438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842a9f2b77cb402b%3A0x6dd664f8efa6ad28!2sAntonio%20Aguilar%20155%2C%20Lomas%20de%20San%20Luis%2C%20San%20Luis%2C%20S.L.P.!5e0!3m2!1ses!2smx!4v1656629783420!5m2!1ses!2smx"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe> */}

                {/* Leaflet */}
                <iframe
                  className="w-full h-full"
                  title="Simple Maps"
                  aria-label="Simple Maps"
                  scrolling="no"
                  src="https://simple-apps.ai/widget.php?pageId=c24vq&amp;compId=comp-jtuer8jv&amp;viewerCompId=comp-jtuer8jv&amp;siteRevision=194&amp;viewMode=site&amp;deviceType=desktop&amp;locale=es&amp;regionalLanguage=es&amp;width=434&amp;height=234&amp;instance=-NM_jVRgUdT0PPfFPZjl7E2cLi5Y-YIYseJlnT-qM-I.eyJpbnN0YW5jZUlkIjoiOGY4YWJkZTYtZmEyOC00NTBiLTgxZmQtZmJlMzhhN2QxNjIzIiwiYXBwRGVmSWQiOiIxMmY3ZDk5YS1mZjRmLTQ3ODgtOGE2OS00NmNiMDVjZTBiMmMiLCJzaWduRGF0ZSI6IjIwMjItMDctMjVUMjA6MzI6NDUuNDUwWiIsImRlbW9Nb2RlIjpmYWxzZSwiYWlkIjoiMzVmMWFiYTktMDY4Mi00YjY4LWE4YzEtNTA5YjJmMDU5ZWYzIiwic2l0ZU93bmVySWQiOiJiODgyOWY3ZS02ZDBkLTQ0OWItOWU4YS02MDc2YjFhODU5MzUifQ&amp;commonConfig=%7B%22brand%22%3A%22wix%22%2C%22bsi%22%3A%22a2c94110-fdec-4680-b053-74a470e6e349%7C1%22%2C%22BSI%22%3A%22a2c94110-fdec-4680-b053-74a470e6e349%7C1%22%7D&amp;vsi=674abe57-e848-408b-8e09-8f1add326962"
                  allowFullScreen=""
                  allowtransparency="true"
                  allowvr="true"
                  frameBorder="0"
                  allow="autoplay;camera;microphone;geolocation;vr"
                ></iframe>
              </div>
              <div className="relative">
                <a
                  id="zl-url"
                  className="zl-url"
                  href="https://www.doctoralia.com.mx/fernando-hernandez-perez/ortopedista-traumatologo/san-luis-potosi"
                  rel="nofollow"
                  data-zlw-doctor="fernando-hernandez-perez"
                  data-zlw-type="big_with_calendar"
                  data-zlw-opinion="false"
                  data-zlw-hide-branding="true"
                >
                  Fernando Hernández Pérez - Doctoralia.com.mx
                </a>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
