import React from "react";
import Modal from "../components/Modal";
import img1 from "../assets/img/img1.webp";
import img2 from "../assets/img/img2.webp";
import img3 from "../assets/img/img3.webp";
import img4 from "../assets/img/img4.webp";
import img5 from "../assets/img/img5.webp";
import img6 from "../assets/img/img6.webp";
import img7 from "../assets/img/img7.webp";
import img8 from "../assets/img/img8.webp";
import img9 from "../assets/img/img9.webp";
import img10 from "../assets/img/img10.webp";
import img11 from "../assets/img/img11.webp";
import img12 from "../assets/img/img12.webp";
import img13 from "../assets/img/img13.webp";
import img14 from "../assets/img/img14.webp";
import Texto1 from "../descriptions/Texto1";
import Texto2 from "../descriptions/Texto2";
import Texto3 from "../descriptions/Texto3";
import Texto4 from "../descriptions/Texto4";
import Texto5 from "../descriptions/Texto5";
import Texto6 from "../descriptions/Texto6";
import Texto7 from "../descriptions/Texto7";
import Texto8 from "../descriptions/Texto8";
import Texto9 from "../descriptions/Texto9";
import Texto10 from "../descriptions/Texto10";
import Texto11 from "../descriptions/Texto11";
import Texto12 from "../descriptions/Texto12";
import Texto13 from "../descriptions/Texto13";
import Texto14 from "../descriptions/Texto14";

export default function Informate() {
  const items = [
    {
      id: 1,
      name: "¿Qué es la cirugía de ligamento cruzado anterior (LCA)? y cuando es conveniente la intervención.",
      imageSrc: `${img1}`,
      imageAlt: "image",
      text: <Texto1 />,
    },
    {
      id: 2,
      name: '¿Conoces el termino "Hombro de lanzador"?',
      imageSrc: `${img2}`,
      imageAlt: "image",
      text: <Texto2 />,
    },
    {
      id: 3,
      name: "Las peores lesiones deportivas en las Olimpiadas",
      imageSrc: `${img3}`,
      imageAlt: "image",
      text: <Texto3 />,
    },

    {
      id: 4,
      name: "Accidentes más impactantes en los juegos olímpicos",
      imageSrc: `${img4}`,
      imageAlt: "image",
      text: <Texto4 />,
    },
    {
      id: 5,
      name: "Lesión de Cartílago Articular",
      imageSrc: `${img5}`,
      imageAlt: "image",
      text: <Texto5 />,
    },
    {
      id: 6,
      name: "¿Qué es una Plica? y ¿Cómo se trata?",
      imageSrc: `${img6}`,
      imageAlt: "image",
      text: <Texto6 />,
    },
    {
      id: 7,
      name: "¿Cómo diferencio entre una luxación, una fractura o un esguince?",
      imageSrc: `${img7}`,
      imageAlt: "image",
      text: <Texto7 />,
    },
    {
      id: 8,
      name: "¿Qué es una lesión meniscal?",
      imageSrc: `${img8}`,
      imageAlt: "image",
      text: <Texto8 />,
    },
    {
      id: 9,
      name: 'Conoces acerca del síndrome de la banda iliotibial o "rodilla de corredor"',
      imageSrc: `${img9}`,
      imageAlt: "image",
      text: <Texto9 />,
    },
    {
      id: 10,
      name: "¿Por qué me duele el hombro? Y ¿Qué es el manguito rotador?",
      imageSrc: `${img10}`,
      imageAlt: "image",
      text: <Texto10 />,
    },
    {
      id: 11,
      name: "¿ENTRENAR O NO ENTRENAR HE AHÍ EL DILEMA?",
      imageSrc: `${img11}`,
      imageAlt: "image",
      text: <Texto11 />,
    },
    {
      id: 12,
      name: "Lesión de Menisco",
      imageSrc: `${img12}`,
      imageAlt: "image",
      text: <Texto12 />,
    },
    {
      id: 13,
      name: "¿Artroscopía o Cirugía Abierta de Hombro?",
      imageSrc: `${img13}`,
      imageAlt: "image",
      text: <Texto13 />,
    },
    {
      id: 14,
      name: "Me duele la rodilla al subir y bajar escaleras (Síndrome Patelofemoral)",
      imageSrc: `${img14}`,
      imageAlt: "image",
      text: <Texto14 />,
    },
  ];

  return (
    <>
      <div className="py-12 bg-sky-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="mt-2 text-center text-3xl leading-8 font-bold tracking-tight text-gray-700 sm:text-4xl">
              Conoce más
            </p>
          </div>

          <div className="relative max-w-2xl mx-auto py-12 sm:py-12 lg:py-12 lg:max-w-none flex gap-6 snap-x snap-mandatory overflow-x-auto pb-10">
            <div className="snap-center shrink-0">
              <div className="shrink-0 w-4 sm:w-48"></div>
            </div>

            {items.map((item) => (
              <div
                key={item.id}
                className="snap-center shrink-0 group bg-indigo-300"
              >
                <Modal data={item} />
              </div>
            ))}

            <div className="snap-center shrink-0">
              <div className="shrink-0 w-4 sm:w-48"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
