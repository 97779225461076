import React from "react";

export default function Texto13() {
  return (
    <>
      <div>
        Existen múltiples lesiones en la articulación del hombro que pueden
        presentarse tanto en un deportista como en la población normal, en la
        última década una de las ramas de la Ortopedia con mayor desarrollo y
        avances tecnológicos es la de la Artroscopía.
        <br></br>
        <br></br>
        El tratamiento artroscópico consiste en realizar el procedimiento
        quirúrgico mediante pequeñas incisiones de 4 mm cada una apoyándonos en
        el uso de un lente especial que permite visualizar las estructuras de la
        articulación y trabajar mediante instrumental especializado pudiendo
        reparar la mayoría de las lesiones.
        <br></br>
        <br></br>
        Algunas de las lesiones del hombro exitosamente tratadas a través de
        artroscopia son:
        <br></br>
        <br></br>
        <ol>
          <li>• Pinzamiento Subacromial</li>
          <li>• Lesión o Ruptura de Manguito Rotador</li>
          <li>• Luxaciones recurrentes de Hombro</li>
          <li>• Inestabilidad de Hombro</li>
          <li>• Tendinitis de Manguito Rotador</li>
          <li>• Tendinitis del Bíceps</li>
          <li>• Artrosis Acromioclavicular</li>
          <li>• Artrosis de Hombro</li>
          <li>• Hombro Congelado</li>
          <li>• Lesiones de Cartílago Articular del Hombro</li>
        </ol>
        <br></br>
        <br></br>
        Actualmente mediante el tratamiento artroscópico de las lesiones de
        hombro se pueden obtener excelentes resultados logrando, en manos
        entrenadas, mayor tasa de éxito en comparación con la cirugía
        tradicional.
        <br></br>
        <br></br>
        La gran diferencia en el tratamiento artroscópico consiste en que aun a
        través de pequeñas incisiones se logra una visualización mucho mejor de
        las lesiones en comparación con la cirugía tradicional abierta ya que
        las imágenes están magnificadas y proyectadas en una pantalla e alta
        definición permitiendo visualizar y trabajar en áreas que son fácilmente
        accesibles a través de la cirugía abierta.
        <br></br>
        <br></br>
        Otra gran ventaja es que al ser incisiones pequeñas que no lastiman
        músculos ni la capsula articular de forma importante teniendo una
        recuperación mucho menos dolorosa, notablemente fácil y rápida. Esta
        ventaja, a la par de los nuevos diseños de instrumental e implantes con
        los que efectuamos las reparaciones y las técnicas nuevas de reparación
        permiten una movilización inmediata del hombro incluso el mismo día de
        la cirugía.
        <br></br>
        <br></br>
        Esta cirugía de mínima invasión, en conjunto con nuevos métodos de
        anestesia/ analgesia postquirúrgica permite que casi cualquier
        procedimiento quirúrgico de hombro se lleve a cabo de forma ambulatoria.
        Es decir el paciente ingresa en la mañana se interviene y por la tarde
        regresa a casa con mínima restricción a la movilidad y poco o nulo
        dolor.
        <br></br>
        <br></br>
        El paciente es revisado a las 24 horas, y a los 10 días después de
        retirar los 3 o 4 puntos de sutura utilizados. En este momento inicia un
        programa de rehabilitación temprana mucho más agresivo y rápido que el
        que permite la cirugía abierta tradicional. Esto permite a su vez una
        reintegración laboral y/o deportiva mucho más rápida que con la cirugía
        tradicional.
        <br></br>
        <br></br>
        Evidentemente la cirugía artroscópica es una excelente opción de
        tratamiento seguro y efectivo.
        <br></br>
        <br></br>
        Siendo de suma importancia que este y todos los tratamientos sean
        llevados a cabo por un médico adecuadamente entrenado en esta técnica.
      </div>
    </>
  );
}
