import React from "react";
import img1 from "../assets/img/pepe_pidal.png";
import img2 from "../assets/img/javier_munoz.png";
import img3 from "../assets/img/gustavo_quintero.png";
import img4 from "../assets/img/mark_borders.png";

export default function Opiniones() {
  const opiniones = [
    {
      id: 1,
      name: "Pepe Pidal",
      employment: "Entrenador de Baloncesto",
      imgSrc: `${img1}`,
      active: true,
      opinion:
        "Gracias, Dr. Fernando, por el gran trabajo que hizo en la Liga Nacional de Baloncesto Profesional de México. Muy profesional de su parte, de lo mejor que me tocó trabajar en mi carrera de coach",
    },
    {
      id: 2,
      name: "Javier Muñoz",
      employment: "Entrenador de Baloncesto",
      imgSrc: `${img2}`,
      active: false,
      opinion:
        "Gracias Dr. Fernando por su dedicación y conocimientos a servicio de nuestro staff y equipo en la LNBP 2018-2019. Reconocido profesional para ayudarnos a todos para ser mejores",
    },
    {
      id: 3,
      name: "Gustavo Quintero",
      employment: "Entrenador Profesional de Baloncesto",
      imgSrc: `${img3}`,
      active: false,
      opinion:
        "El Dr. Fernando es el encargado de dar el visto bueno clínicamente sobre cada jugador que llega al club Santos de San Luis con sus revisiones médicas y físicas, despúes de eso se encarga de llevar la evolución y problemas que pueda tener el jugador ya sean lesiones ó enfermedades",
    },
    {
      id: 4,
      name: "Mark Borders",
      employment: "Basketball Professional Player",
      imgSrc: `${img4}`,
      active: false,
      opinion:
        "Doctor Fernando is great, he's kind with all this patients. Doesn't matter if you're a professional athlete or just a hard working parent or a young kid, He's always there to answer phone calls and any questions you have.",
    },
  ];
  return (
    <>
      <div className="bg-sky-900 py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto py-10 sm:py-24 lg:py-10 lg:max-w-none">
            <div className="mt-6 lg:text-center">
              <p className="mt-0 text-3xl leading-8 font-bold tracking-tight text-white text-center sm:text-4xl">
                Opiniones
              </p>
            </div>
            <div
              id="carouselExampleCaptions"
              className="carousel slide relative"
              data-bs-ride="carousel"
            >
              <div className="mt-10 carousel-inner relative w-full overflow-hidden">
                {opiniones.map((item) => (
                  <div
                    key={item.id}
                    className={
                      item.active
                        ? "carousel-item active relative float-left w-full text-center"
                        : "carousel-item relative float-left w-full text-center"
                    }
                  >
                    <p className="opinion text-3xl italic justify-center mx-auto text-white max-w-4xl">
                      "{item.opinion}"
                    </p>
                    <div className="mt-12 mb-6 flex justify-center">
                      <img
                        className="rounded-full w-36 h-36 shadow-lg"
                        src={item.imgSrc}
                        alt={item.name}
                      />
                    </div>
                    <p className="mt-2 text-center tracking-widest">
                      <span className="text-3xl text-lime-600 font-extrabold">
                        {item.name}
                      </span>
                      <br />
                      <span className="text-white text-lg">
                        {item.employment}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon inline-block bg-no-repeat"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previo</span>
              </button>
              <button
                className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon inline-block bg-no-repeat"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
