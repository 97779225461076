import imagen_logo from "../assets/img/bannerp.png";

export default function Banner() {
  return (
    <>
      <div className="banner border-transparent w-screen sm:w-full md:w-full">
        <img className="imagen" src={imagen_logo} alt="banner" />
      </div>

      <div className="polygon_1">
        <svg
          className="absolute bottom-0 overflow-hidden"
          preserveAspectRatio="none"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-slate-200 fill-white"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </>
  );
}
