import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route   } from 'react-router-dom';
// import routes from './config/routes';
import './App.scss';

import LayoutBasic from './layouts/LayoutBasic';
import Testimonios from './pages/Testimonios';
import Inicio from './pages/Inicio';
import i18n from 'i18next';
import { initReactI18next, useTranslation ,Trans } from 'react-i18next';



const translationsEn = { welcome: "welcome", sample: "Sample <bold><italics>Text</italics></bold>"};
const translationsEs = { welcome: "bienvenido", sample: "Ejemplo de <bold><italics>Texto</italics></bold>"};

i18n.use(initReactI18next)
.init({
  resources:{
    en: { translation: translationsEn },
    es: { translation: translationsEs },
  },
  lng:"es",
  fallbackLng: "es",
  interpolation:{ escapeValue:false },
});


function App() {
  const { t } = useTranslation();
  const onChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);

  };

  return (
    <Suspense fallback="Loading...">
    
    <Router>
      <LayoutBasic>
    
        <Routes>
        <Route path="/testimonios" element={<Testimonios/>} exact={true} />
        <Route path="/" element={<Inicio/>} />
        </Routes>
        {/* <h1>{t("welcome")}</h1>
        <p>
          <Trans components={{bold:<strong/>, italics:<italics/>}}>sample</Trans>
         
        </p>
      <select name="language" onChange={onChangeLanguage}>
        <option value="en">English</option>
        <option value="es">Español</option>

      </select> */}
      </LayoutBasic>
    </Router>
    </Suspense>
  );
}

export default App;
