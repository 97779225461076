import React from "react";

export default function Texto11() {
  return (
    <>
      <div>
        Poco a poco todos volvemos a la normalidad: en redes sociales vemos a
        los PROs entrenar esperando a un calendario incierto, y los populares
        recuperamos nuestros hábitos de entrenamiento.
        <br></br>
        <br></br>
        Pero una pregunta ronda en el ambiente, ya que hay un gran número de
        deportistas que han tenido que pasar la enfermedad: ¿Qué pasa si he
        tenido COVID-19 y quiero salir a entrenar? Vamos a analizarlo.
        <br></br>
        <br></br>
        Aaron Baggish, responsable médico del Maratón de Boston, y Ben Levine,
        profesor de medicina y cardiología en el Centro Médico de la Universidad
        de Texas Southwest, también son de la opinión de que hay que volver con
        cautela a la práctica deportiva.
        <br></br>
        De hecho, han llegado a desarrollar un "algoritmo" que cubre a todos los
        corredores, desde aquellos que nunca dieron positivo a aquellos que
        necesitaban ser hospitalizados. Según su informe, aparecido el pasado 8
        de junio, recomiendan una serie de prácticas, entre la que destacan lo
        que han denominado "evitar el complejo Ícaro".
        <br></br>
        <br></br>
        Ícaro murió después de volar demasiado cerca del sol, no voló a una
        altitud moderada. Pues bien, ambos cardiólogos comparan esta historia
        con el deseo de los corredores de entrenar cuanto antes sin apreciar el
        posible riesgo de lesiones cardíacas.
        <br></br>
        Baggish y Levine proponen el enfoque que se presenta a continuación,
        dividido en cuatro partes para corredores y otras personas activas:
        <br></br>
        <br></br>
        <ol>
          <li>
            Si no se ha dado positivo: se puede hacer ejercicio libremente
            teniendo en cuenta el distanciamiento social.
          </li>
          <li>
            Si el resultado es positivo, pero sin síntomas: se recomienda no
            ejercicio durante dos semanas después de su prueba positiva. Después
            se puede volver lentamente al ejercicio siguiendo las instrucciones
            del personal sanitario.
          </li>
          <li>
            Si el resultado es positivo y se tiene síntomas leves: No se debería
            reanudar el ejercicio hasta dos semanas después de la resolución de
            la enfermedad; considerar la evaluación de un cardiólogo.
          </li>
          <li>
            Si se ha sido hospitalizado con COVID-19: Al igual que el anterior
            caso, pero con más precaución y controlando el deterioro clínico.
          </li>
        </ol>
        <br></br>
        <br></br>
        Otros neumólogos recomiendan no hacer nada de ejercicio durante la
        enfermedad ni una semana después de haberla pasado.
        <br></br>
        Cómo actuar dos semanas después del COVID-19
        <br></br>A partir de este momento ya todos los estudios apuntan a que se
        podría hacer ejercicio.
        <br></br>
        Aun así, habría que tener en mente que la tos y la dificultad para
        respirar podrían no haberse ido. Así que es previsible que sintamos aún
        un poco de incomodidad en la práctica deportiva.
        <br></br>
        Es obvio que si los síntomas no disminuyen día a día se deberían acudir
        al médico.
        <br></br>
        <br></br>
        <h1 className="font-bold">Experiencia personal</h1>
        <br></br>
        Molly Demellier, exrunner de la NCAA, ha contado en un artículo para
        runner cómo ha sido su experiencia pasando el coronavirus y volviendo a
        la actividad posterior.
        <br></br>
        <br></br>
        Su primer día de entrenamiento lo recuerda como algo muy doloroso:
        "Empecé a correr de manera muy lenta, con dolores", explica.
        <br></br>
        <span className="font-bold">
          "Poco a poco notaba como mis caderas y rodillas rompían las cadenas
          invisibles que las ataban. Mis músculos gritaron y mis pulmones
          ardieron durante esas ocho millas".
        </span>
        <br></br>
        <br></br>
        Desde entonces, ha ido recuperando fuerza y ​​resistencia.{" "}
        <span className="font-bold">
          "Ahora corro de siete a diez millas seis días a la semana, cumpliendo
          las pautas de distanciamiento social corriendo en áreas menos
          concurridas cuando hay menos personas afuera, como temprano en la
          mañana o en la noche".
        </span>
        <br></br>
        <br></br>
        Fuente original.
      </div>
    </>
  );
}
