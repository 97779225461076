import React from "react";

export default function Texto7() {
  return (
    <>
      <div>
        El hombro es una articulación compleja formada por dos huesos
        principalmente: La cabeza del húmero y la glenoides que es la parte
        distal de la escapula (También llamada omoplato y/o paleta). Esta
        articulación asemeja a una esfera sobre un plato y a diferencia de otras
        articulaciones requiere mucha ayuda y de un trabajo en conjunto de
        varias estructuras para mantenerse estable.
        <br></br>
        <br></br>
        La primera de ellas, una especie de empaque llamada lábrum, crea un tope
        y un efecto de vacío que sujeta a la articulación, la capsula articular
        es una estructura elástica que atrapa también la cabeza del humero y la
        adosa a la glenoides. Finalmente el conjunto de músculos que mueven el
        hombro tales como el deltoides y los del manguito rotador mantienen de
        forma dinámica la cabeza del humero centrada en la glenoides.
        <br></br>
        <br></br>
        Cuando existe un trauma o movimiento violento muy fuerte estas
        estructuras pueden ceder permitiendo que la cabeza del humero se salga
        de lugar. Al salir de su lugar (Luxarse) la cápsula articular, el lábrum
        y las estructuras musculares se desgarran.
        <br></br>
        <br></br>
        Normalmente esto es muy doloroso y se requiere de atención especializada
        para recolocar el hombro en su posición adecuada sin producir más
        lesiones al momento de volver a poner todo en su lugar.
        <br></br>
        <br></br>
        En el pasado se utilizaban métodos muy agresivos para reacomodar la
        articulación (reducción) que muchas veces lastimaban más estructuras que
        las que inicialmente se habían producido durante la luxación.
        <br></br>
        <br></br>
        Actualmente esta maniobra se realiza mediante tracción gentil y bajo
        anestesia general para general el menor dolor y no causar más daño del
        existente.
        <br></br>
        <br></br>
        En pacientes de baja actividad y mayores de 30 años de edad, cuando la
        reducción de la luxación es realizada de forma eficiente, y se realiza
        un seguimiento adecuado inmovilizando la articulación por un periodo de
        entre 2 y 3 semanas (Preferentemente con un aparato de rotación externa)
        realizando una rehabilitación especializada. Existe una gran posibilidad
        de que el problema no se repita en el futuro.
        <br></br>
        <br></br>
        Cuando la luxación s epresenta en pacientes con alta demanda de
        actividad y en especial en pacientes menores a 30 años de edad,
        actualmente se recomienda realizar una resonancia magnetica para
        identificar las estructuras lesionadas y cuando se demuestra una lesión
        labral y/o de la cápsula articular es muy recomendable realizar una
        reparación quirúrgica inmediata para recolocar las estructuras en su
        lugar y re-tensar la capsula articular.
        <br></br>
        <br></br>
        Esa cirugía puede realizarse de forma tradicional con una incisión de
        10cm aproximadamente o de forma artroscópica con 3 pequeñas heridas de 4
        a 6mm y el uso de un lente e instrumental especializado de artroscopia.
        <br></br>
        <br></br>
        Este manejo mas agresivo se debe a que esta demostrado que pacientes
        menores de 30 años con lesiones establecidas de labrum articular
        presentan un 80% de probabilidad de una nueva luxación a medianoplazo y
        este riesgo disminuye a casi el 10% cuando son intervenidos de forma
        inmediata reparando las estructuras dañadas.
        <br></br>
        <br></br>
        De la misma manera hay pacientes que tienen articulaciones muy laxas que
        los hacen más susceptibles a luxaciones y que sin llegar a la luxación
        completa de la articulació, esta es muy inestable. En estos pacientes
        ocasionalmente realizamos cirugías artroscópicas para tensar la capsula
        articular dándole mayor estabilidad a la articulación aún y cuando nunca
        hubieran sufrido una luxación.
        <br></br>
        <br></br>
        Actualmente en manos expertas los resultados mediante las técnicas
        artroscópicas son muy superiores a las técnicas abiertas, sin embargo
        existen aún casos, sobre todo en aquellos pacientes en que a
        consecuencia de las luxaciones repetitivas causan lesiones que pueden
        deformar incluso las estructuras óseas donde se requiere realizar algún
        procedimiento mayor.
        <br></br>
        <br></br>
        Si tu hombro se ha luxado más de una vez, o persiste el dolor después de
        haber sido recolocado no dudes en acudir a la brevedad con un
        especialista a valorarte
      </div>
    </>
  );
}
